import * as React from "react";
import {
  Button,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  useDisclosure,
  Heading,
  Text
} from "@chakra-ui/react";
import Payment from "../../pages/Payment";

interface PaymentButton {
  amount: string;
}

const PaymentButton: React.FC<PaymentButton> = ({ amount }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button
        w="100%"
        mt="2%"
        textColor="white"
        bgColor="#143345"
        fontFamily="body"
        onClick={onOpen}
      >
        Enroll Now
      </Button>

      <Modal size="xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Complete Your Payment Here</ModalHeader>
          <ModalCloseButton />
          {/* <Text p="5%">Please contact Divij Garg at 847-431-2004 to complete your payment.</Text> */}
           <Payment
            amount={amount}
            orderID=""
            onApproveMessage=""
            onErrorMessage=""
          />
        {/* <div id="paypal-container-QWWGVGCRSJSFN"></div> */}
      
        </ModalContent>
      </Modal>
    </>
  );
};

export default PaymentButton;
