import { Box, HStack, Image, Text } from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";

export default function Footer() {
  return (
    <Box w="100%" h="100%" bgColor="#143345">
      <HStack mt="2%" mb="2%" w="100%" textAlign="left" spacing="3%">
        <Image
          src="https://i.ibb.co/K9WZnnZ/logo.png"
          // alt=""
          ml="5%"
          maxW="3%"
          // objectFit="cover"
        />
        <Text as={ReactRouterLink} to="/DSAT" color="white">
          DSAT Prep
        </Text>
        <Text as={ReactRouterLink} to="/ACT" color="white">
          ACT Prep
        </Text>
        <Text as={ReactRouterLink} to="/subject" color="white">
          Subject Tutoring
        </Text>
      </HStack>
    </Box>
  );
}
