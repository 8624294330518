import {
  Box,
  Heading,
  VStack,
  HStack,
  useBreakpointValue,
  Text,
  Image,
  Divider,
  List,
  ListItem,
  ListIcon,
  Button,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import NavBar from "../Overall/Navbar";
import Footer from "../Overall/Footer";
import Consultation from "../Overall/Consultation";
import PaymentButton from "../Overall/PaymentButton";
function DSATPrivateGroup() {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const isDesktop = useBreakpointValue({ base: false, md: true });

  if (isDesktop) {
    return (
      <VStack w="100%">
        <NavBar />
        <Heading textAlign="center" color="#143345" mt="5%">
          Private and Group Combination
        </Heading>
        <Text>$350/mo</Text>
        <Text textAlign="center" w="60%" fontFamily="book">
          Tailored for students who wish for overall content review, but also
          desire extra attention to certain skills.
        </Text>
        <Box w="15%">
          <PaymentButton amount="350"></PaymentButton>
        </Box>
        <HStack justifyContent="center" w="85%" p="5%" spacing="5%">
          <VStack w="100%" minH="70vh">
            <Image src={"../icons/arm.png"} w="20%" />
            <Heading size="md" w="100%" textAlign="center">
              Instruction
            </Heading>
            <List>
              <ListItem>
                <HStack>
                  <Image
                    src={"../icons/check.png"}
                    boxSize="1.5rem"
                    alt="Bullet point"
                    // mr={1}
                  />
                  <Box>
                    <Text fontWeight="medium" fontSize="sm">
                      1:1 one-hour tutoring session/week
                    </Text>
                  </Box>
                </HStack>
              </ListItem>
              <ListItem>
                <HStack>
                  <Image
                    src={"../icons/check.png"}
                    boxSize="1.5rem"
                    alt="Bullet point"
                    // mr={1}
                  />
                  <Box>
                    <Text fontWeight="medium" fontSize="sm">
                      2 one-hour group sessions/week
                    </Text>
                  </Box>
                </HStack>
              </ListItem>
            </List>
            <Text mt="2%" fontFamily="book" textAlign="center" fontSize="sm">
              In private lessons, a tutor will work with you to identify your
              specific skill gaps and provide further assistance.
            </Text>
            <Text fontFamily="book" textAlign="center" fontSize="sm">
            Our group lessons allow entry at any time. They will alternate equally
              between English and Math subjects, until the entire DSAT
              curriculum is covered - typically in 3 months. Each group session
              contains up to five students, matching them by a diagnostic DSAT.
            </Text>
          </VStack>
          <VStack w="100%" minH="70vh">
            <Image src={"../icons/teacher.png"} w="20%" />
            <Heading size="md" w="100%" textAlign="center">
              Reinforcement
            </Heading>
            <List>
              <ListItem>
                <HStack>
                  <Image
                    src={"../icons/check.png"}
                    boxSize="1.5rem"
                    alt="Bullet point"
                    // mr={1}
                  />
                  <Box>
                    <Text fontWeight="medium" fontSize="sm">
                      Personalized homework assignments
                    </Text>
                  </Box>
                </HStack>
              </ListItem>
              <ListItem>
                <HStack>
                  <Image
                    src={"../icons/check.png"}
                    boxSize="1.5rem"
                    alt="Bullet point"
                    // mr={1}
                  />
                  <Box>
                    <Text fontWeight="medium" fontSize="sm">
                      2 homework assignments/week
                    </Text>
                  </Box>
                </HStack>
              </ListItem>
            </List>
            <Text mt="2%" fontFamily="book" textAlign="center" fontSize="sm">
              The personalized homework assignments will target specific weak
              points that the student has.
            </Text>
            <Text fontFamily="book" textAlign="center" fontSize="sm">
              The group homework assignments contain problems related to the
              topic that was covered in each previous lesson. Additionally,
              students are assigned weekly readings to build up their reading
              comprehension skills.
            </Text>
          </VStack>

          <VStack w="100%" minH="70vh">
            <Image src={"../icons/hands.png"} w="20%" />
            <Heading size="md" w="100%" textAlign="center">
              Support
            </Heading>
            <List>
              <ListItem>
                <HStack>
                  <Image
                    src={"../icons/check.png"}
                    boxSize="1.5rem"
                    alt="Bullet point"
                    // mr={1}
                  />
                  <Box>
                    <Text fontSize="sm">
                      Proctored bi-weekly practice tests
                    </Text>
                  </Box>
                </HStack>
              </ListItem>

              <ListItem>
                <HStack>
                  <Image
                    src={"../icons/check.png"}
                    boxSize="1.5rem"
                    alt="Bullet point"
                    // mr={1}
                  />
                  <Box>
                    <Text fontSize="sm">Access to weekly office hours </Text>
                  </Box>
                </HStack>
              </ListItem>
            </List>
            <Text mt="2%" fontFamily="book" textAlign="center" fontSize="sm">
              In our weekly office hours, students can come in and ask any
              questions they may have. We give the proctored bi-weekly practice
              tests so that students can track their progress.
            </Text>
          </VStack>
        </HStack>
        <Consultation />
        <Footer />
      </VStack>
    );
  } else if (isMobile) {
    return (
      <VStack w="100%">
        <NavBar />
        <Heading textAlign="center" color="#143345" w="80%" mt="5%">
          Private and Group Combination
        </Heading>
        <Text>$350/mo</Text>
        <Text textAlign="center" w="80%" fontFamily="book">
          Tailored for students who wish for overall content review, but also
          desire extra attention to certain skills.
        </Text>
        <Box w="60%">
          <PaymentButton amount="350"></PaymentButton>
        </Box>
        <VStack justifyContent="center" w="85%" p="5%" spacing="30px">
          <VStack w="100%">
            <Image src={"../icons/arm.png"} w="20%" />
            <Heading size="md" w="100%" textAlign="center">
              Instruction
            </Heading>
            <List>
              <ListItem>
                <HStack>
                  <Image
                    src={"../icons/check.png"}
                    boxSize="1.5rem"
                    alt="Bullet point"
                    // mr={1}
                  />
                  <Box>
                    <Text fontWeight="medium" fontSize="sm">
                      1:1 one-hour tutoring session/week
                    </Text>
                  </Box>
                </HStack>
              </ListItem>
              <ListItem>
                <HStack>
                  <Image
                    src={"../icons/check.png"}
                    boxSize="1.5rem"
                    alt="Bullet point"
                    // mr={1}
                  />
                  <Box>
                    <Text fontWeight="medium" fontSize="sm">
                      2 one-hour group sessions/week
                    </Text>
                  </Box>
                </HStack>
              </ListItem>
            </List>
            <Text mt="2%" fontFamily="book" textAlign="center" fontSize="sm">
              In private lessons, a tutor will work with you to identify your
              specific skill gaps and provide further assistance.
            </Text>
            <Text fontFamily="book" textAlign="center" fontSize="sm">
            Our group lessons allow entry at any time. They will alternate equally
              between English and Math subjects, until the entire DSAT
              curriculum is covered - typically in 3 months. Each group session
              contains up to five students, matching them by a diagnostic DSAT.
            </Text>
          </VStack>
          <VStack w="100%">
            <Image src={"../icons/teacher.png"} w="20%" />
            <Heading size="md" w="100%" textAlign="center">
              Reinforcement
            </Heading>
            <List>
              <ListItem>
                <HStack>
                  <Image
                    src={"../icons/check.png"}
                    boxSize="1.5rem"
                    alt="Bullet point"
                    // mr={1}
                  />
                  <Box>
                    <Text fontWeight="medium" fontSize="sm">
                      Personalized homework assignments
                    </Text>
                  </Box>
                </HStack>
              </ListItem>
              <ListItem>
                <HStack>
                  <Image
                    src={"../icons/check.png"}
                    boxSize="1.5rem"
                    alt="Bullet point"
                    // mr={1}
                  />
                  <Box>
                    <Text fontWeight="medium" fontSize="sm">
                      2 homework assignments/week
                    </Text>
                  </Box>
                </HStack>
              </ListItem>
            </List>
            <Text mt="2%" fontFamily="book" textAlign="center" fontSize="sm">
              The personalized homework assignments will target specific weak
              points that the student has.
            </Text>
            <Text fontFamily="book" textAlign="center" fontSize="sm">
              The group homework assignments contain problems related to the
              topic that was covered in each previous lesson. Additionally,
              students are assigned weekly readings to build up their reading
              comprehension skills.
            </Text>
          </VStack>

          <VStack w="100%">
            <Image src={"../icons/hands.png"} w="20%" />
            <Heading size="md" w="100%" textAlign="center">
              Support
            </Heading>
            <List>
              <ListItem>
                <HStack>
                  <Image
                    src={"../icons/check.png"}
                    boxSize="1.5rem"
                    alt="Bullet point"
                    // mr={1}
                  />
                  <Box>
                    <Text fontSize="sm">
                      Proctored bi-weekly practice tests
                    </Text>
                  </Box>
                </HStack>
              </ListItem>

              <ListItem>
                <HStack>
                  <Image
                    src={"../icons/check.png"}
                    boxSize="1.5rem"
                    alt="Bullet point"
                    // mr={1}
                  />
                  <Box>
                    <Text fontSize="sm">Access to weekly office hours </Text>
                  </Box>
                </HStack>
              </ListItem>
            </List>
            <Text mt="2%" fontFamily="book" textAlign="center" fontSize="sm">
              In our weekly office hours, students can come in and ask any
              questions they may have. We give the proctored bi-weekly practice
              tests so that students can track their progress.
            </Text>
          </VStack>
        </VStack>
        <Consultation />
        <Footer />
      </VStack>
    );
  }
  return null;
}
export default DSATPrivateGroup;
