import {
  Box,
  Grid,
  GridItem,
  Heading,
  VStack,
  useBreakpointValue,
  Text,
} from "@chakra-ui/react";
import TutorInfo from "../TutorInfo";
import { tutorsList } from "../tutorsList";
function DSATTutors() {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const isDesktop = useBreakpointValue({ base: false, md: true });

  if (isDesktop) {
    return (
      <Box width="80%">
        <VStack spacing="50px" width="100%">
          <Heading
            width="100%"
            fontSize="3xl"
            textAlign="center"
            color="black"
            // fontWeight="bold"
            mb="5%"
          >
            Meet our Tutors
          </Heading>
          <Grid templateColumns="repeat(2, 1fr)" gap={20}>
            <GridItem>
              <TutorInfo
                title={tutorsList[0].name}
                img={tutorsList[0].img}
                desc={tutorsList[0].desc}
                tutor={tutorsList[0].score}
                score=""
                accomplishment=""
                college=""
                degree=""
              />
            </GridItem>
            <GridItem>
              <TutorInfo
                title={tutorsList[1].name}
                img={tutorsList[1].img}
                desc={tutorsList[1].desc}
                tutor={tutorsList[1].score}
                score=""
                accomplishment=""
                college=""
                degree=""
              />
            </GridItem>
          </Grid>
        </VStack>
        {/* <Footer /> */}
      </Box>
    );
  } else if (isMobile) {
    return (
      <Box width="80%">
        <VStack spacing="50px" width="100%">
          <Heading
            width="100%"
            fontSize="3xl"
            textAlign="center"
            color="black"
            // fontWeight="bold"
            mb="5%"
          >
            Meet our Tutors
          </Heading>
          <Grid templateColumns="repeat(1, 2fr)" gap={20}>
            <GridItem>
              <TutorInfo
                title={tutorsList[0].name}
                img={tutorsList[0].img}
                desc={tutorsList[0].desc}
                tutor={tutorsList[0].score}
                score=""
                accomplishment=""
                college=""
                degree=""
              />
            </GridItem>
            <GridItem>
              <TutorInfo
                title={tutorsList[1].name}
                img={tutorsList[1].img}
                desc={tutorsList[1].desc}
                tutor={tutorsList[1].score}
                score=""
                accomplishment=""
                
                college=""
                degree= ""
              />
            </GridItem>
          </Grid>
        </VStack>
      </Box>
    );
  }
  return null;
}
export default DSATTutors;
