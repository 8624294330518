import { Button, Center, Flex, Text, VStack } from "@chakra-ui/react";
import { Link } from "react-router-dom";

export default function SimpleCard({ schoolName, datesAttended, description }) {
  return (
    <Flex
      w="100%"
      h="100%"
      // borderWidth="10px"
      borderRadius="10px"
      borderColor="gray.800"
      borderTop="gray"
      boxShadow="lg"
    >
      {" "}
      <VStack w="100%" h="100%">
        <Center w="100%">
          <Text
            w="100%"
            mt="10%"
            textAlign="center"
            color="black"
            fontSize="xl"
          >
            {schoolName}
          </Text>
        </Center>

        <Text p="5%" align="center" color="black" fontSize="lg">
          {datesAttended}
        </Text>
        <Link to={description}>
          <Button bgColor="#043345" textColor="white" mb="10%" w="100%" boxShadow="lg" justifyContent="center">
            <Text color="white" fontSize="sm" textAlign="center">
              Explore
            </Text>
          </Button>
        </Link>
      </VStack>
    </Flex>
  );
}
