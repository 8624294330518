import {
  Box,
  Heading,
  VStack,
  HStack,
  useBreakpointValue,
  Text,
  Image,
  Divider,
  List,
  ListItem,
  ListIcon,
  Button,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import PaymentButton from "../Overall/PaymentButton";

function DSATPrivatePackages() {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const isDesktop = useBreakpointValue({ base: false, md: true });

  if (isDesktop) {
    return (
      <VStack w="100%" mt="5%" justifyContent="center">
        <Heading>Packages</Heading>
        <Text>
          Upon enrollment, we will be in contact to schedule class timings.{" "}
        </Text>
        <HStack justifyContent="center" w="70%" mt="5%" spacing="10%">
          <Box
            boxShadow="lg"
            rounded="5%"
            borderColor="gray"
            borderRadius="20px"
            w="100%"
          >
            <VStack p="5%">
              <Text mt="5%" textColor="gray">
                Individual
              </Text>
              <Heading>$50/hr</Heading>
              <PaymentButton amount="50"></PaymentButton>
            </VStack>
          </Box>
          <Box
            boxShadow="lg"
            rounded="5%"
            borderColor="gray"
            borderRadius="20px"
            w="100%"
          >
            <VStack p="5%">
              <Text mt="5%" textColor="gray">
                10 Hour Package
              </Text>
              <Heading>$475</Heading>
              <PaymentButton amount="475"></PaymentButton>
            </VStack>
          </Box>
          <Box
            boxShadow="lg"
            rounded="5%"
            borderColor="gray"
            borderRadius="20px"
            w="100%"
          >
            <VStack p="5%">
              <Text textColor="gray" mt="5%">
                20 Hour Package
              </Text>
              <Heading>$900</Heading>
              <PaymentButton amount="900"></PaymentButton>
            </VStack>
          </Box>
        </HStack>
      </VStack>
    );
  } else if (isMobile) {
    return (
      <VStack w="100%" mt="5%" justifyContent="center">
        <Heading>Packages</Heading>
        <Text textAlign="center">
          Upon enrollment, we will be in contact to schedule class timings.{" "}
        </Text>
        <VStack justifyContent="center" w="70%" mt="5%" spacing="40px">
          <Box
            boxShadow="lg"
            rounded="5%"
            borderColor="gray"
            borderRadius="20px"
            padding="10px"
            
            borderTop = "2px solid #EEEEEEFF"
            w="100%"
          >
            <VStack p="5%">
              <Text mt="5%" textColor="gray">
                Individual
              </Text>
              <Heading>$50/hr</Heading>
              <PaymentButton amount="50"></PaymentButton>
            </VStack>
          </Box>
          <Box
            boxShadow="lg"
            rounded="5%"
            borderColor="gray"
            borderRadius="20px"
            w="100%"
          >
            <VStack p="5%">
              <Text mt="5%" textColor="gray">
                10 Hour Package
              </Text>
              <Heading>$475</Heading>
              <PaymentButton amount="475"></PaymentButton>
            </VStack>
          </Box>
          <Box
            boxShadow="lg"
            rounded="5%"
            borderColor="gray"
            borderRadius="20px"
            w="100%"
          >
            <VStack p="5%">
              <Text textColor="gray" mt="5%">
                20 Hour Package
              </Text>
              <Heading>$900</Heading>
              <PaymentButton amount="900"></PaymentButton>
            </VStack>
          </Box>
        </VStack>
      </VStack>
    );
  }
  return null;
}
export default DSATPrivatePackages;
