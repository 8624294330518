import {
  Box,
  Heading,
  VStack,
  HStack,
  useBreakpointValue,
  Text,
  Image,
  Divider,
  List,
  ListItem,
  ListIcon,
  Button,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

function ACTPlan() {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const isDesktop = useBreakpointValue({ base: false, md: true });
  const items1 = [
    "2 one-hour group sessions/week",
    "2 homework assignments/week",
    "Access to weekly office hours ",
    "Proctored bi-weekly practice tests",
  ];
  const items2 = [
    "1:1 one-hour tutoring session/week",
    "Personalized homework assignments",
    "2 one-hour group sessions/week",
    "2 homework assignments/week",
    "Access to weekly office hours",
    "Proctored bi-weekly practice tests",
  ];
  const items3 = [
    "1:1 one-hour tutoring session/week",
    "Personalized homework assignments",
    "Access to weekly office hours",
    "Proctored bi-weekly practice tests",
  ];
  if (isDesktop) {
    return (
      <VStack mt="5%">
        <Heading>Find the Right Plan for You</Heading>
        <HStack w="60%" mt="5%" spacing="5%">
          {/* <Box
            boxShadow="lg"
            rounded="5%"
            borderColor="gray"
            borderRadius="20px"
          >
            <VStack>
              <Heading fontSize="xl" textColor="#043345" mt="5%">
                Group Lessons
              </Heading>
              <HStack>
                <Heading fontSize="3xl">$200</Heading>
                <Text>/mo</Text>
              </HStack>

              <Text w="90%" textAlign="center" fontSize="sm">
                Ideal for students looking for in-depth content assistance and
                strategy, while reinforcing concepts with practice.
              </Text>
              <Divider
                borderColor="gray"
                borderWidth="1px"
                w="60%"
                mt="5%"
                mb="5%"
              />
              <List w=" 90%" spacing={2} justifyContent="left">
                {items1.map((item, index) => (
                  <ListItem key={index}>
                    <HStack align="start">
                      <Image
                        src={"../icons/check.png"}
                        boxSize="1.5rem"
                        alt="Bullet point"
                        mr={1}
                      />{" "}
                      <Box>
                        <Text fontSize="sm">{item}</Text>
                      </Box>
                    </HStack>
                  </ListItem>
                ))}
              </List>
              <Button
                mt="5%"
                mb="10%"
                w="50%"
                textColor="white"
                bgColor="#143345"
                fontFamily="Intro Regular"
                as={Link}
                to="/ACT/group-lessons"
              >
                Learn More
              </Button>
            </VStack>
          </Box>

          <Box
            boxShadow="lg"
            rounded="5%"
            borderColor="gray"
            borderRadius="20px"
          >
            <VStack>
              <Box
                h="30px"
                justifyContent="center"
                w="40%"
                textColor="white"
                borderRadius="5px"
                bgColor="#00b6c7"
              >
                <Heading mt="5%" textAlign="center" fontSize="md">
                  {" "}
                  Best Value
                </Heading>
              </Box>
              <Heading fontSize="xl" textColor="#043345" mt="5%">
                Group and Private Combo
              </Heading>
              <HStack>
                <Heading fontSize="3xl">$350</Heading>
                <Text>/mo</Text>
              </HStack>

              <Text w="90%" textAlign="center" fontSize="sm">
                Tailored for students who wish for overall content review, but
                also desire extra attention to certain skills.
              </Text>
              <Divider
                borderColor="gray"
                borderWidth="1px"
                w="60%"
                mt="5%"
                mb="5%"
              />
              <List w=" 90%" spacing={2} justifyContent="left">
                {items2.map((item, index) => (
                  <ListItem key={index}>
                    <HStack align="start">
                      <Image
                        src={"../icons/check.png"}
                        boxSize="1.5rem"
                        alt="Bullet point"
                        mr={1}
                      />{" "}
                      <Box>
                        <Text fontSize="sm">{item}</Text>
                      </Box>
                    </HStack>
                  </ListItem>
                ))}
              </List>
              <Button
                mt="5%"
                mb="10%"
                w="50%"
                textColor="white"
                bgColor="#143345"
                fontFamily="Intro Regular"
                as={Link}
                to="/ACT/group-private"
              >
                Learn More
              </Button>
            </VStack>
          </Box> */}

          <Box
            boxShadow="lg"
            rounded="5%"
            borderColor="gray"
            borderRadius="20px"
          >
            <VStack>
              <Heading fontSize="xl" textColor="#043345" mt="5%">
                Private Lessons
              </Heading>
              <HStack>
                <Heading fontSize="3xl">$50</Heading>
                <Text>/hr</Text>
              </HStack>

              <Text w="90%" textAlign="center" fontSize="sm">
                Ideal for students looking for in-depth content assistance and
                strategy, while reinforcing concepts with practice.
              </Text>
              <Divider
                borderColor="gray"
                borderWidth="1px"
                w="60%"
                mt="5%"
                mb="5%"
              />
              <List w=" 90%" spacing={2} justifyContent="left">
                {items3.map((item, index) => (
                  <ListItem key={index}>
                    <HStack align="start">
                      <Image
                        src={"../icons/check.png"}
                        boxSize="1.5rem"
                        alt="Bullet point"
                        mr={1}
                      />{" "}
                      <Box>
                        <Text fontSize="sm">{item}</Text>
                      </Box>
                    </HStack>
                  </ListItem>
                ))}
              </List>
              <Button
                mt="5%"
                mb="10%"
                w="50%"
                textColor="white"
                bgColor="#143345"
                fontFamily="Intro Regular"
                as={Link}
                to="/ACT/private"
              >
                Learn More{" "}
              </Button>
            </VStack>
          </Box>
        </HStack>
      </VStack>
    );
  } else if (isMobile) {
    return (
      <VStack mt="5%">
        <Heading textAlign="center" p="1%">Find the Right Plan for You</Heading>
        <VStack w="80%" mt="5%" spacing="20px">
          {/* <Box
            boxShadow="lg"
            rounded="5%"
            borderColor="gray"
            borderRadius="20px"
          >
            <VStack>
              <Heading fontSize="xl" textColor="#043345" mt="5%">
                Group Lessons
              </Heading>
              <HStack>
                <Heading fontSize="3xl">$200</Heading>
                <Text>/mo</Text>
              </HStack>

              <Text w="90%" textAlign="center" fontSize="sm">
                Ideal for students looking for in-depth content assistance and
                strategy, while reinforcing concepts with practice.
              </Text>
              <Divider
                borderColor="gray"
                borderWidth="1px"
                w="60%"
                mt="5%"
                mb="5%"
              />
              <List w=" 90%" spacing={2} justifyContent="left">
                {items1.map((item, index) => (
                  <ListItem key={index}>
                    <HStack align="start">
                      <Image
                        src={"../icons/check.png"}
                        boxSize="1.5rem"
                        alt="Bullet point"
                        mr={1}
                      />{" "}
                      <Box>
                        <Text fontSize="sm">{item}</Text>
                      </Box>
                    </HStack>
                  </ListItem>
                ))}
              </List>
              <Button
                mt="5%"
                mb="10%"
                w="50%"
                textColor="white"
                bgColor="#143345"
                fontFamily="Intro Regular"
                as={Link}
                to="/ACT/group-lessons"
              >
                Learn More
              </Button>
            </VStack>
          </Box>

          <Box
            boxShadow="lg"
            rounded="5%"
            borderColor="gray"
            borderRadius="20px"
          >
            <VStack>
              <Box
                h="30px"
                justifyContent="center"
                w="40%"
                textColor="white"
                borderRadius="5px"
                bgColor="#00b6c7"
              >
                <Heading mt="5%" textAlign="center" fontSize="md">
                  {" "}
                  Best Value
                </Heading>
              </Box>
              <Heading fontSize="xl" textColor="#043345" mt="5%">
                Group and Private Combo
              </Heading>
              <HStack>
                <Heading fontSize="3xl">$350</Heading>
                <Text>/mo</Text>
              </HStack>

              <Text w="90%" textAlign="center" fontSize="sm">
                Tailored for students who wish for overall content review, but
                also desire extra attention to certain skills.
              </Text>
              <Divider
                borderColor="gray"
                borderWidth="1px"
                w="60%"
                mt="5%"
                mb="5%"
              />
              <List w=" 90%" spacing={2} justifyContent="left">
                {items2.map((item, index) => (
                  <ListItem key={index}>
                    <HStack align="start">
                      <Image
                        src={"../icons/check.png"}
                        boxSize="1.5rem"
                        alt="Bullet point"
                        mr={1}
                      />{" "}
                      <Box>
                        <Text fontSize="sm">{item}</Text>
                      </Box>
                    </HStack>
                  </ListItem>
                ))}
              </List>
              <Button
                mt="5%"
                mb="10%"
                w="50%"
                textColor="white"
                bgColor="#143345"
                fontFamily="Intro Regular"
                as={Link}
                to="/ACT/group-private"
              >
                Learn More
              </Button>
            </VStack>
          </Box> */}

          <Box
            boxShadow="lg"
            rounded="5%"
            borderColor="gray"
            borderRadius="20px"
          >
            <VStack>
              <Heading fontSize="xl" textColor="#043345" mt="5%">
                Private Lessons
              </Heading>
              <HStack>
                <Heading fontSize="3xl">$50</Heading>
                <Text>/hr</Text>
              </HStack>

              <Text w="90%" textAlign="center" fontSize="sm">
                Ideal for students looking for in-depth content assistance and
                strategy, while reinforcing concepts with practice.
              </Text>
              <Divider
                borderColor="gray"
                borderWidth="1px"
                w="60%"
                mt="5%"
                mb="5%"
              />
              <List w=" 90%" spacing={2} justifyContent="left">
                {items3.map((item, index) => (
                  <ListItem key={index}>
                    <HStack align="start">
                      <Image
                        src={"../icons/check.png"}
                        boxSize="1.5rem"
                        alt="Bullet point"
                        mr={1}
                      />{" "}
                      <Box>
                        <Text fontSize="sm">{item}</Text>
                      </Box>
                    </HStack>
                  </ListItem>
                ))}
              </List>
              <Button
                mt="5%"
                mb="10%"
                w="50%"
                textColor="white"
                bgColor="#143345"
                fontFamily="Intro Regular"
                as={Link}
                to="/ACT/private"
              >
                Learn More{" "}
              </Button>
            </VStack>
          </Box>
        </VStack>
      </VStack>
    );
  }
  return null;
}
export default ACTPlan;
