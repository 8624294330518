import {
  Box,
  Grid,
  GridItem,
  Heading,
  VStack,
  HStack,
  useBreakpointValue,
  Text,
} from "@chakra-ui/react";
import { InlineWidget } from "react-calendly";

function Consultation() {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const isDesktop = useBreakpointValue({ base: false, md: true });

  if (isDesktop) {
    return (
      <Box width="100%" mt="5%" mb="5%">
        <HStack spacing="50px" width="100%">
          <VStack w="50%" ml="10%" textAlign="left">
            <Text w="100%" fontSize="30px">
              Book a free 30 minute consultation.
            </Text>
            <Text mr="25%" w="75%" textAlign="left">
              Need more guidance? Our tutor Divij is offering a free 30 minute
              consultation. During this time, feel free to ask any questions
              about our offerings or gain general advice.{" "}
            </Text>
          </VStack>
          <Box w="40%" mr="10%">
            <InlineWidget
              styles={{ width: "100%", height: "400px" }}
              url="https://calendly.com/divijgarg04/tutoring"
            />
          </Box>
        </HStack>
      </Box>
    );
  } else if (isMobile) {
    return (
      <Box mt="5%" width="100%">
        <VStack spacing="50px" width="100%">
          <VStack w="100%">
            <Text textAlign="center" w="80%" fontSize="30px">
              Book a free 30 minute consultation.
            </Text>
            <Text w="80%" textAlign="center">
              Need more guidance? Our tutor Divij is offering a free 30 minute
              consultation. During this time, feel free to ask any questions
              about our offerings or gain general advice.{" "}
            </Text>
          </VStack>
          <Box w="80%">
            <InlineWidget
              styles={{ width: "100%", height: "400px" }}
              url="https://calendly.com/divijgarg04/tutoring"
            />
          </Box>
        </VStack>
      </Box>
    );
  }
  return null;
}

export default Consultation;
