import { extendTheme } from '@chakra-ui/react'
import "@fontsource/viga"
import "@fontsource/righteous"
import "@fontsource/roboto-mono"
import "../styles.css"
const theme = extendTheme({
    fonts: {
        heading: 'Intro Bold',
        body: 'Intro Regular',
        book: 'Intro Book'
    },
    
})



export default theme