import {
  Box,
  Grid,
  GridItem,
  Heading,
  VStack,
  useBreakpointValue,
  Text,
} from "@chakra-ui/react";
import Project from "./TutorInfo";
import { tutorsList } from "./tutorsList";
function Tutors() {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const isDesktop = useBreakpointValue({ base: false, md: true });
  const projects = tutorsList;

  if (isDesktop) {
    return (
      <Box width="100%">
        <VStack spacing="50px" width="100%">
          <Text
            width="100%"
            fontSize="2xl"
            textAlign="center"
            color="black"
            // fontWeight="bold"
            mb="5%"
            mt="5%"
          >
            Our amazing team is here for you.
          </Text>
          <Grid  w = "90%" templateColumns="repeat(3, 1fr)" gap={20}>
            {projects.map((project, index) => (
              <GridItem key={index}>
                <Project
                  title={project.name}
                  img={project.img}
                  desc=""
                  tutor={project.tutor}
                  score={project.score}
                  accomplishment={project.accomplishment}
                  college={project.college}
                  degree={project.degree}
                />
              </GridItem>
            ))}
          </Grid>
        </VStack>
        {/* <Footer /> */}
      </Box>
    );
  } else if (isMobile) {
    return (
      <Box width="100%">
        <VStack spacing="50px" width="100%">
          <Text
            width="100%"
            fontSize="2xl"
            textAlign="center"
            color="black"
            // fontWeight="bold"
            mt="5%"
          >
            Our amazing team is here for you.
          </Text>
          <Grid templateColumns="repeat(1, 1fr)" gap={20}>
            {projects.map((project, index) => (
              <GridItem key={index}>
                <Project
                  title={project.name}
                  img={project.img}
                  desc={project.desc}
                  tutor={project.tutor}
                  score={project.score}
                  accomplishment={project.accomplishment}
                  college={project.college}
                  degree={project.degree}
                />
              </GridItem>
            ))}
          </Grid>
        </VStack>
        {/* <Footer /> */}
      </Box>
    );
  }
  return null;
}
export default Tutors;
