import React from "react";
import { VStack, HStack } from "@chakra-ui/react";
import Simple from "../components/Overall/Navbar";
import Footer from "../components/Overall/Footer";
import { Text, Heading, Box } from "@chakra-ui/react";

export default function Failure() {
  return (
    <Box
      w="100%"
      h="100%"
      minH="100vh"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Simple />
      <HStack w="90%" spacing="5%" p="5%">
        <Heading w="50%" textAlign="left">
          There was an issue with your payment.
        </Heading>
        <Text w="50%" textAlign="left">
          Please contact Divij Garg at 847-431-2004 or
          scholarsphere100@gmail.com and we'd be happy to help you resolve it.
        </Text>
      </HStack>
      <Footer />
    </Box>
  );
}
