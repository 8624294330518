"use client";

import {
  Box,
  Flex,
  Avatar,
  HStack,
  Text,
  IconButton,
  Button,
  Menu,
  MenuButton,
  Image,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
  useColorModeValue,
  Stack,
} from "@chakra-ui/react";
import { Link as ChakraLink } from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";

import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";

interface Props {
  children: React.ReactNode;
}

const NavLink = (props: Props) => {
  const { children } = props;

  return (
    <Box
      as="a"
      px={2}
      py={1}
      rounded={"md"}
      _hover={{
        textDecoration: "none",
        // bg: useColorModeValue("gray.200", "gray.700"),
      }}
      href={"#"}
    >
      {children}
    </Box>
  );
};

export default function Simple() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Box w="100%" px={4}>
        <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
          <IconButton
            size={"md"}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label={"Open Menu"}
            display={{ md: "none" }}
            onClick={isOpen ? onClose : onOpen}
          />
          <HStack spacing={4} alignItems={"center"}>
            <ReactRouterLink to="/">Scholar Sphere</ReactRouterLink>
            <HStack
              as={"nav"}
              spacing={10}
              display={{ base: "none", md: "flex" }}
            >
              <ReactRouterLink to="/DSAT">DSAT Prep</ReactRouterLink>
              <ReactRouterLink to="/ACT">ACT Prep</ReactRouterLink>
              <ReactRouterLink to="/subject">Subject Tutoring</ReactRouterLink>
              <ReactRouterLink to="/consultation">
                Book a Consultation
              </ReactRouterLink>
              {/* <ReactRouterLink to="/">Contact Us</ReactRouterLink> */}
            </HStack>
          </HStack>
          <Flex alignItems={"center"}>
            <Menu>
              <MenuButton
                as={Button}
                rounded={"full"}
                variant={"link"}
                cursor={"pointer"}
                minW={0}
              >
                <ChakraLink as={ReactRouterLink} to="/">
                  <Image w="40px" src="images/bluelogo.png" />
                </ChakraLink>{" "}
              </MenuButton>
            </Menu>
          </Flex>
        </Flex>

        {isOpen ? (
          <Box pb={4} display={{ md: "none" }}>
            <Stack as={"nav"} spacing={4}>
              <ReactRouterLink to="/DSAT">DSAT Prep</ReactRouterLink>
              <ReactRouterLink to="/ACT">ACT Prep</ReactRouterLink>
              <ReactRouterLink to="/subject">Subject Tutoring</ReactRouterLink>
              <ReactRouterLink to="/consultation">
                Book a Consultation
              </ReactRouterLink>
              {/* <ReactRouterLink to="/">Contact Us</ReactRouterLink> */}
            </Stack>
          </Box>
        ) : null}
      </Box>

      {/* <Box p={4}>Main Content Here</Box> */}
    </>
  );
}
