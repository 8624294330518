import {
  Box,
  Grid,
  GridItem,
  Heading,
  VStack,
  HStack,
  useBreakpointValue,
  Text,
  Image,
} from "@chakra-ui/react";

function ACTExplanation() {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const isDesktop = useBreakpointValue({ base: false, md: true });

  if (isDesktop) {
    return (
      <Box width="100%" mb="5%">
        <VStack width="100%">
          <Heading w="50%" textAlign="center" mt="5%">
            Unmatched Support to Help You Succeed on the ACT.
          </Heading>
          <Text mt="1%">
            We provide high-quality tutoring at market low prices.
          </Text>
          {/* <Box
            as="video"
            controls
            src="path/to/your/video.mp4"
            width="40%"
            borderRadius="lg"
            boxShadow="lg"
            mt="1%"
          /> */}

          <HStack w="100%" mt="5%" spacing="5%" justifyContent="center">
            {/* <VStack>
              <Image src={"../icons/upwardsCheck.png"} w="30%" />
              <Heading size="md" w="70%" textAlign="center">
                7+ Point Average Point Increase
              </Heading>
            </VStack> */}
            <VStack>
              <Image src={"../icons/teacher.png"} w="30%" />
              <Heading size="md" w="70%" textAlign="center">
                35+ Average Tutor ACT Scores{" "}
              </Heading>
            </VStack>

            <VStack>
              <Image src={"../icons/clock.png"} w="30%" />
              <Heading size="md" w="70%" textAlign="center">
                3+ Years of Tutoring Experience
              </Heading>
            </VStack>
          </HStack>
        </VStack>
      </Box>
    );
  } else if (isMobile) {
    return (
      <Box width="100%" mb="5%">
        <VStack width="100%">
          <Heading w="80%" textAlign="center" mt="5%">
            Unmatched Support to Help You Succeed on the ACT.
          </Heading>
          <Text textAlign="center" p="1%" >
            We provide high-quality tutoring at market low prices.
          </Text>
          {/* <Box
            as="video"
            controls
            src="path/to/your/video.mp4"
            width="80%"
            borderRadius="lg"
            boxShadow="lg"
            mt="1%"
          /> */}

          <VStack w="80%" mt="5%" spacing="30px">
            {/* <VStack>
              <Image src={"../icons/upwardsCheck.png"} w="30%" />
              <Heading size="md" w="70%" textAlign="center">
                7+ Point Average Point Increase
              </Heading>
            </VStack> */}
            <VStack>
              <Image src={"../icons/teacher.png"} w="30%" />
              <Heading size="md" w="70%" textAlign="center">
                35+ Average Tutor ACT Scores{" "}
              </Heading>
            </VStack>

            <VStack>
              <Image src={"../icons/clock.png"} w="30%" />
              <Heading size="md" w="70%" textAlign="center">
                3+ Years of Tutoring Experience
              </Heading>
            </VStack>
          </VStack>
        </VStack>
      </Box>
    );
  }
  return null;
}
export default ACTExplanation;
