import { Box, HStack, Heading, Text, VStack } from "@chakra-ui/react";
import React from "react";
import Simple from "../components/Overall/Navbar";
import Footer from "../components/Overall/Footer";
import { useNavigate } from "react-router-dom";
import PayPalIntegration from "../components/Overall/PaypalIntegration";
interface PaymentProps {
  amount: string;
  orderID: string;
  onApproveMessage: string;
  onErrorMessage: string;
}
export default function Payment(props: PaymentProps) {
  const navigate = useNavigate();
  return (
    <Box ml="10%" mr="10%" w="80%"  justifyContent="center">
      <Text mb="5%" >
        Your total is ${props.amount}. Please select a payment method to
        proceed.
      </Text>
      <PayPalIntegration navigate={navigate} amount={props.amount} />
    </Box>
  );
}
