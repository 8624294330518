import {
  Box,
  Button,
  HStack,
  Heading,
  Text,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

import { useEffect, useState } from "react";
import Consultation from "../components/Overall/Consultation";
import Footer from "../components/Overall/Footer";
import Nav from "../components/Overall/Navbar";
import SimpleCard from "../components/SimpleCard";
import Testimonials from "../components/Testimonials";
import Tutors from "../components/Tutors";

function Home() {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const isDesktop = useBreakpointValue({ base: false, md: true });
  const [currentIndex, setCurrentIndex] = useState(0);
  const images = [];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 4000);

    return () => clearInterval(interval);
  }, [images.length]);
  if (isDesktop) {
    return (
      <VStack minH="100vh" h="100" w="100%" bgColor="white">
        <Nav />
        <HStack width="100%" bgColor="#043345">
          <Box width="100%" justifyContent="center">
            <VStack w="100%" p="10%">
              <Heading textAlign="center" color="white" fontSize="40px">
                Seeking high-quality and affordable tutoring? You're at the
                right place.
              </Heading>
              <Text color="white" fontSize="20px" textAlign="center">
                Scholar Sphere was started by two college siblings who understand the importance of attention and care in education. We are committed towards providing engaging and personalized aid.
              </Text>
            </VStack>
          </Box>
          {/* <Box
            height="60%"
            width="40%"
            bgColor="gray"
            ml="5%"
            borderRadius="20px"
          ></Box> */}
        </HStack>

        <VStack w="100%">
          <Box height="100%" width="100%">
            <Text color="black" fontSize="30px" textAlign="center" mt="5%">
              We support your entire academic journey.
            </Text>
          </Box>
          <HStack mt="5%" w="80%" spacing="5%">
            <SimpleCard
              schoolName="Digital SAT Prep"
              datesAttended="The DSAT can make or break your college admissions game. Our tutors are here to help, with our students seeing a 100+ average point increase. Take control of your DSAT journey!"
              description="/DSAT"
            />
            <SimpleCard
              schoolName="Subject Tutoring"
              datesAttended="Need a little help outside of school? We offer K-12 private tutoring with tutors that have years of experience for the most affordable prices on the market. "
              description="/subject"
            />
            <SimpleCard
              schoolName="ACT Prep"
              datesAttended="Stuck at the same stubborn score? Our tutors have significant experience with the ACT and understand the test. Get into your dream school with the help of our expert tutors."
              description="/ACT"
            />
          </HStack>

          <VStack mt="5%" height="100%" width="100%" bgColor="#0d9aac">
            <Text
              mt="3%"
              w="45%"
              color="white"
              fontSize="20px"
              textAlign="center"
            >
              Signups are now open for our DSAT Bootcamp! This 3 month intensive
              summer bootcamp will cover all the SAT content, and has average
              score increases of 100+ points within just two weeks. Secure your
              spot!
            </Text>
            <Link to="/DSAT">
              <Button
                bgColor="white"
                textColor="white"
                marginTop="20%"
                marginBottom="20%"
                boxShadow="lg"
              >
                <Text color="black" fontSize="sm" textAlign="center">
                  Learn More
                </Text>
              </Button>
            </Link>
          </VStack>
        </VStack>
        <Tutors />
        {/* <Testimonials /> */}
        <Consultation />
        <Footer />
      </VStack>
    );
  } else if (isMobile) {
    return (
      <VStack w="100%" bgColor="white" spacing="10px">
        <Nav />
        <VStack width="100%" bgColor="#043345">
          <Heading p="5%" color="white" fontSize="40px" w="100%">
            Seeking high quality and affordable tutoring? You're at the right
            place.
          </Heading>
          {/* <Box
            // minH="70vh"
            as="video"
            width="90%"
            bgColor="gray"
            p="5%"
            borderRadius="20px"
          ></Box> */}
          <Text p="5%" color="white" fontSize="20px">
          Scholar Sphere was started by two college siblings who understand the importance of attention and care in education. We are committed towards providing engaging and personalized aid.
          </Text>
        </VStack>

        <VStack w="100%">
          <Box width="100%">
            <Text
              ml="5%"
              mr="5%"
              color="black"
              fontSize="30px"
              textAlign="center"
              mt="5%"
            >
              We support your entire academic journey.
            </Text>
          </Box>
          <VStack mt="5%" w="80%" spacing="5%">
            <SimpleCard
              schoolName="Digital SAT Prep"
              datesAttended="The DSAT can make or break your college admissions game. Our tutors are here to help, with our students seeing a 100+ average point increase. Take control of your DSAT journey!"
              description="/dsat"
            />
            <SimpleCard
              schoolName="Subject Tutoring"
              datesAttended="Need a little help outside of school? We offer K-12 private tutoring with tutors that have years of experience for the most affordable prices on the market. "
              description="/subject"
            />
            <SimpleCard
              schoolName="ACT Prep"
              datesAttended="Stuck at the same stubborn score? Our tutors are highly experienced and fully understand the test. Get into your dream school with the help of our expert tutors. "
              description="/act"
            />
          </VStack>

          <VStack p="5%" mt="30%" width="100%" bgColor="#0d9aac">
            <Text
              // mt="3%"
              w="100%"
              color="white"
              fontSize="20px"
              textAlign="center"
            >
              Signups are now open for our DSAT Bootcamp! This 3 month intensive
              summer bootcamp will cover all the SAT content, and has average
              score increases of 100+ points within just two weeks. Secure your
              spot!
            </Text>
            <Link to="/dsat">
              <Button
                bgColor="white"
                textColor="white"
                // marginTop="20%"
                // marginBottom="20%"
                boxShadow="lg"
              >
                <Text color="black" fontSize="sm" textAlign="center">
                  Learn More
                </Text>
              </Button>
            </Link>
          </VStack>
        </VStack>
        <Tutors />
        {/* <Testimonials /> */}
        <Consultation />
        <Footer />
      </VStack>
    );
  }

  return null;
}
export default Home;
