import {
  Box,
  Grid,
  GridItem,
  Heading,
  VStack,
  HStack,
  useBreakpointValue,
  Text,
  Image,
} from "@chakra-ui/react";

function SchoolExplanation() {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const isDesktop = useBreakpointValue({ base: false, md: true });

  if (isDesktop) {
    return (
      <Box width="100%" mb="5%">
        <VStack width="100%">
          <Heading w="50%" textAlign="center" mt="5%" textColor="#043345">
          High quality and affordable tutoring for all students K-12
          </Heading>
          <Text fontSize="lg" fontFamily="Intro Book" mt="1%" mb="2%">
          Whether you need elementary, middle, or high school tutoring, we have got you covered!
          </Text>
          {/* <Box
            as="video"
            controls
            src="path/to/your/video.mp4"
            width="40%"
            borderRadius="lg"
            boxShadow="lg"
            mt="1%"
          /> */}
          
          <HStack  w="100%" mt="5%" spacing = "5%" justifyContent="center">
            {/* <VStack >
              <Image src={"../icons/upwardsCheck.png"} w="40%" />
              <Heading size="md" w="70%" textAlign="center">7+ Point Grade Increase</Heading>
            </VStack> */}
            <VStack>
              <Image src={"../icons/teacher.png"}  w="40%" />
              <Heading size="md" w="70%" textAlign="center">Experienced School Teachers</Heading>
            </VStack>

            <VStack>
              <Image src={"../icons/clock.png"}   w="40%"/>
              <Heading size="md" w="70%" textAlign="center">Flexible Convenient Hours</Heading>
            </VStack>
          </HStack>
        </VStack>
      </Box>
    );
  } else if (isMobile) {
    return (
      <Box width="100%" mb="5%">
        <VStack width="100%">
          <Heading w="80%" textAlign="center" mt="5%" textColor="#043345">
          High quality and affordable tutoring for all students K-12
          </Heading>
          <Text textAlign="center" fontSize="lg" fontFamily="Intro Book" mt="1%" mb="2%">
          Whether you need elementary, middle, or high school tutoring, we have got you covered!
          </Text>
          {/* <Box
            as="video"
            controls
            src="path/to/your/video.mp4"
            width="80%"
            borderRadius="lg"
            boxShadow="lg"
            mt="1%"
          /> */}
          
          <VStack  w="100%" mt="5%"  spacing = "30px" justifyContent="center">
            {/* <VStack >
              <Image src={"../icons/upwardsCheck.png"} w="40%" />
              <Heading size="md" w="70%" textAlign="center">7+ Point Grade Increase</Heading>
            </VStack> */}
            <VStack>
              <Image src={"../icons/teacher.png"}  w="40%" />
              <Heading size="md" w="70%" textAlign="center">Experienced School Teachers</Heading>
            </VStack>

            <VStack>
              <Image src={"../icons/clock.png"}   w="40%"/>
              <Heading size="md" w="70%" textAlign="center">Flexible Convenient Hours</Heading>
            </VStack>
          </VStack>
        </VStack>
      </Box>
    );
  }
  return null;
}
export default SchoolExplanation;
