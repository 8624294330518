export const tutorsList = [
  {
    name: "Divij Garg",
    img: "images/divij.jpg",
    desc: "Divij is a junior at the University of Illinois Urbana Champaign majoring in Computer Science + Mathematics. He’s been a tutor for the past 4 years, working with students specifically on SAT preparation.",
    tutor: "SAT Tutor",
    score: "SAT Score: 1600",
    accomplishment: "SAT Score: 1600",
    college: "B.S. in CS & Math @ University of Illinois Urbana Champaign",
    degree: "Computer Science & Mathematics",
  },
  {
    name: "Leisha Garg",
    img: "images/leisha.png",
    desc: "Leisha is a sophomore at the University of Illinois Urbana Champaign majoring in Computer Science and Statistics. For the past 3 years, she has been working with students to craft personalized SAT preparation plans.",
    tutor: "SAT Tutor",
    score: "SAT Score: 1570",
    accomplishment: "SAT Score: 1570",
    college: "B.S. in CS & Stats @ University of Illinois Urbana Champaign",
    degree: "Computer Science & Statistics",
  },
  {
    name: "Rohan Kher",
    img: "images/rohan.jpeg",
    desc: "Hi! I’m an undergraduate who will be studying Physics at Brown in the fall. In the past, I’ve worked with students as a freelance tutor in science and math and as a teacher’s assistant in a freshman science class. In my free time, I like to read, work out, and watch sports",
    tutor: "SAT/ACT Tutor",
    score: "",
    accomplishment: "ACT Score: 36",
    college: "B.S. in Physics @ Brown University",
    degree: "Physics",
  },
  {
    name: "Thandiwe Ogbonna",
    img: "images/Thandiwe.jpeg",
    desc: "I am a professional tutor with over 10 years of experience helping students prepare for the SAT. I live in Orlando, FL, and enjoy reading and learning new things in my free time.",
    tutor: "SAT Tutor",
    score: "",
    accomplishment: "10+ Years of Experience",
    college: "B.A. in Economics @ Virginia Tech",
    degree: "Economics",
  },

  {
    name: "Pranav Meraga",
    img: "images/Pranav.png",
    desc: "I'm a sophomore at UC Berkeley studying aerospace engineering and computer science. I have been tutoring for 6 years, and am constantly looking to educate others to entrust curiosity within others while also teaching myself about new approaches of learning.",
    tutor: "SAT Tutor",
    score: "",
    accomplishment: "SAT Score: 1570",
    college: "B.S. in Aerospace Engineering @ UC Berkeley",
    degree: "Aerospace Engineering",
  },

  {
    name: "Alexandra Hunt",
    img: "images/Alexandra.jpg",
    desc: "Post my graduation as salutatorian at Paw Paw High school and my walk across the stage after obtaining my teaching degree from Michigan State University's College of Education in 2011, I began teaching ACT test preparation classes for The Princeton Review. Since then, I've worked with many, many students both in the USA and abroad to secure their goals of higher education and succeed on a variety of undergraduate and graduate exams; while I love test prep in general, the ACT is still my favorite exam to teach and tutor!",
    tutor: "ACT Tutor",
    score: "",
    accomplishment: "10+ Years of Tutoring",
    college: "B.S. in Special Education @ Michigan State",
    degree: "Special Education",
  },
];
