import { VStack, HStack } from "@chakra-ui/react";
import Simple from "../components/Overall/Navbar";
import Footer from "../components/Overall/Footer";
import { Text, Heading, Box } from "@chakra-ui/react";

export default function Success() {
  return (
    <Box
      w="100%"
      h="100%"
      minH="100vh"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Simple />
      <HStack w="90%" spacing="5%" p="5%">
        <Heading w="50%" textAlign="left">
          Thank you for your payment.
        </Heading>
        <Text w="50%" textAlign="left">
          Your payment has been completed! An email receipt should have been
          sent to you. Within the next day, our tutors will reach out to you to
          get started. If there are any issues, please contact Divij Garg at
          847-431-2004 or scholarsphere100@gmail.com.
        </Text>
      </HStack>
      <Footer />
    </Box>
  );
}
