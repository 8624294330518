import { Route, Routes } from "react-router-dom";
import DSAT from "./pages/DSAT";
import Home from "./pages/Home";
import DSATGroupLessons from "./components/DSAT/DSATGroupLessons";
import DSATPrivateGroup from "./components/DSAT/DSATPrivateGroup";
import DSATPrivate from "./components/DSAT/DSATPrivate";
import Consultation from "./components/Overall/Consultation";
import ConsultationPage from "./pages/ConsultationPage";
import HighSchool from "./pages/HighSchool";
import ACT from "./pages/ACT";
import ACTPrivate from "./components/ACT/ACTPrivate";
import ACTPrivateGroup from "./components/ACT/ACTPrivateGroup";
import ACTGroupLessons from "./components/ACT/ACTGroupLessons";
import Payment from "./pages/Payment";
import Success from "./pages/Success";
import Failure from "./pages/Failure";
// const firebaseConfig = {
//   apiKey: "AIzaSyCqC9_wbY_O6dSYOHvvrmuKoYqfnczPIWs",
//   authDomain: "scholar-sphere-website.firebaseapp.com",
//   projectId: "scholar-sphere-website",
//   storageBucket: "scholar-sphere-website.appspot.com",
//   messagingSenderId: "707752712610",
//   appId: "1:707752712610:web:887acdf3a6355a9dc07afb",
//   measurementId: "G-7C3F8MLW1C"
// };
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export default function App() {
  return (
    <Routes>
      <Route caseSensitive={false} path="/" element={<Home />} />
      <Route caseSensitive={false} path="/consultation" element={<ConsultationPage />} />
      <Route caseSensitive={false} path="/DSAT" element={<DSAT />} />
      <Route  caseSensitive={false} path="/DSAT/group-lessons" element={<DSATGroupLessons />} />
      <Route caseSensitive={false} path="/DSAT/group-private" element={<DSATPrivateGroup />} />
      <Route caseSensitive={false} path="/DSAT/private" element={<DSATPrivate />} />
      <Route caseSensitive={false} path="/subject" element={<HighSchool />} />
      <Route caseSensitive={false} path="/ACT" element={<ACT />} />
      <Route caseSensitive={false} path="/ACT/private" element={<ACTPrivate />} />
      <Route caseSensitive={false} path="/ACT/group-private" element={<ACTPrivateGroup />} />
      <Route caseSensitive={false} path="/ACT/group-lessons" element={<ACTGroupLessons />} />
      <Route caseSensitive={false} path="/success" element={<Success />} />
      <Route caseSensitive={false} path="/payment-fail" element={<Failure />} />
      <Route
        path="/payment"
        element={
          <Payment
            amount="5"
            orderID=""
            onApproveMessage=""
            onErrorMessage=""
          />
        }
      />
    </Routes>
  );
}
