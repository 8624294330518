import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { BrowserRouter, HashRouter } from "react-router-dom";
import App from "./App";
import theme from "./themes/theme";
import "./styles.css"
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
const container = document.getElementById("root");
if (!container) throw new Error("Failed to find the root element");
const root = ReactDOM.createRoot(container);
const firebaseConfig = {
  apiKey: "AIzaSyCXzurI1IYqzofWQOF8UdK49TB0I4F2CLY",
  authDomain: "scholar-sphere-main-w.firebaseapp.com",
  projectId: "scholar-sphere-main-w",
  storageBucket: "scholar-sphere-main-w.appspot.com",
  messagingSenderId: "682821146158",
  appId: "1:682821146158:web:0c2d4048d47443c34ef1bf",
  measurementId: "G-S2PNNFN949"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

root.render(
  <React.StrictMode>
    <ColorModeScript />
    <ChakraProvider theme={theme}>
      <HashRouter>
        <App />
      </HashRouter>
    </ChakraProvider>
  </React.StrictMode>
);
