import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import * as React from "react";
import { useNavigate } from "react-router";

function ParentComponent() {
  const navigate = useNavigate();

  return <PayPalIntegration navigate={navigate} amount="2.00" />;
}

interface PayPalIntegrationProps {
  amount: string;
  navigate: (path: string) => void;
}

const initialState = {
  amount: "2.00",
  orderID: "",
  onApproveMessage: "",
  onErrorMessage: "",
};
const clientId = process.env.REACT_APP_CLIENT_ID;


export default class PayPalIntegration extends React.Component<
  PayPalIntegrationProps,
  typeof initialState
> {
  constructor(props: any) {
    super(props);
    this.state = initialState;

    this.onChange = this.onChange.bind(this);
    this.createOrder = this.createOrder.bind(this);
    this.onApprove = this.onApprove.bind(this);
    this.onError = this.onError.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  onChange(event: React.ChangeEvent<HTMLSelectElement>) {
    this.setState({
      amount: event.target.value,
      orderID: "",
      onApproveMessage: "",
      onErrorMessage: "",
    });
  }

  createOrder(data: Record<string, unknown>, actions: any) {
    return actions.order
      .create({
        purchase_units: [
          {
            amount: {
              value: this.props.amount,
            },
          },
        ],
      })
      .then((orderID: any) => {
        this.setState({ orderID: orderID });
        return orderID;
      });
  }

  onApprove(data: any, actions: any) {
    let app = this;
    return actions.order.capture().then(function (details: any) {
      app.setState({
        onApproveMessage: `Transaction completed by ${details.payer.name.given_name}!`,
      });
      // Navigate to the success page
      app.props.navigate("/success");
    });
  }

  onError(err: Record<string, unknown>) {
    let app = this;
    this.setState({
      onErrorMessage: err.toString(),
      
    });
    app.props.navigate("/payment-fail");

  }

  onClick() {}

  render() {
    return (
      <div style={{ minHeight: "300px" }}>
        <PayPalScriptProvider
          options={{
            clientId:
              clientId || "",
              // hostedButtonId: "V3U4VJZ67W4YG"
          }}
        >
          <PayPalButtons
            createOrder={this.createOrder}
            onApprove={this.onApprove}
            onError={this.onError}
            onClick={this.onClick}
            
          />
        </PayPalScriptProvider>
      </div>
    );
  }
}
