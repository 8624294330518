import {
  Box,
  Heading,
  VStack,
  HStack,
  useBreakpointValue,
  Text,
  Button,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import PaymentButton from "../Overall/PaymentButton";

function ElementarySchoolPlans() {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const isDesktop = useBreakpointValue({ base: false, md: true });

  if (isDesktop) {
    return (
      <VStack w="100%" mt="5%" justifyContent="center">
        <Heading fontSize="2xl">Elementary School Tutoring Packages</Heading>

        <HStack justifyContent="center" w="70%" mt="5%" spacing="10%">
          <Box
            boxShadow="lg"
            rounded="5%"
            borderColor="gray"
            borderRadius="20px"
            w="100%"
          >
            <VStack p="5%">
              <Text mt="5%" textColor="gray">
                45 Minute Class
              </Text>
              <Heading>$35</Heading>
              <PaymentButton amount="35"></PaymentButton>
            </VStack>
          </Box>
          <Box
            boxShadow="lg"
            rounded="5%"
            borderColor="gray"
            borderRadius="20px"
            w="100%"
          >
            <VStack p="5%">
              <Text mt="5%" textColor="gray">
                10 Class Package
              </Text>
              <Heading>$330</Heading>
              <PaymentButton amount="330"></PaymentButton>
            </VStack>
          </Box>
          <Box
            boxShadow="lg"
            rounded="5%"
            borderColor="gray"
            borderRadius="20px"
            w="100%"
          >
            <VStack p="5%">
              <Text textColor="gray" mt="5%">
                20 Class Package
              </Text>
              <Heading>$630</Heading>
              <PaymentButton amount="630"></PaymentButton>
            </VStack>
          </Box>
        </HStack>
      </VStack>
    );
  } else if (isMobile) {
    return (
      <VStack w="100%" mt="5%" justifyContent="center">
        <Heading textAlign="center" w="80%" fontSize="3xl">
          Elementary School Tutoring Packages
        </Heading>

        <VStack justifyContent="center" w="70%" mt="5%" spacing="20px">
          <Box
            boxShadow="lg"
            rounded="5%"
            borderColor="gray"
            borderRadius="20px"
            w="100%"
          >
            <VStack p="5%">
              <Text mt="5%" textColor="gray">
                45 Minute Class
              </Text>
              <Heading>$35</Heading>
              <PaymentButton amount="35"></PaymentButton>
            </VStack>
          </Box>
          <Box
            boxShadow="lg"
            rounded="5%"
            borderColor="gray"
            borderRadius="20px"
            w="100%"
          >
            <VStack p="5%">
              <Text mt="5%" textColor="gray">
                10 Class Package
              </Text>
              <Heading>$330</Heading>
              <PaymentButton amount="330"></PaymentButton>
            </VStack>
          </Box>
          <Box
            boxShadow="lg"
            rounded="5%"
            borderColor="gray"
            borderRadius="20px"
            w="100%"
          >
            <VStack p="5%">
              <Text textColor="gray" mt="5%">
                20 Class Package
              </Text>
              <Heading>$630</Heading>
              <PaymentButton amount="630"></PaymentButton>
            </VStack>
          </Box>
        </VStack>
      </VStack>
    );
  }
  return null;
}
export default ElementarySchoolPlans;
