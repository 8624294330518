import { Box, VStack, useBreakpointValue } from "@chakra-ui/react";

import Nav from "../components/Overall/Navbar";
import Consultation from "../components/Overall/Consultation";
import DSATExplanation from "../components/DSAT/DSATExplanation";
import DSATTutors from "../components/DSAT/DSAT Tutors";
import DSATPlan from "../components/DSAT/DSATPlans";
import Footer from "../components/Overall/Footer";
function ConsultationPage() {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const isDesktop = useBreakpointValue({ base: false, md: true });
  if (isDesktop) {
    return (
      <VStack
        maxW="100%"
        overflow="hidden"
        minH="100vh"
        bgPos="center"
        bgAttachment="fixed"
        bgRepeat="no-repeat"
        bgColor="white"
      >
        <Nav />
        <Consultation />
        <Footer />
      </VStack>
    );
  } else {
    /*need to figure out why background doesn't show up
     */
    return (
      <VStack
        maxW="100%"
        overflow="hidden"
        minH="100vh"
        bgPos="center"
        bgAttachment="fixed"
        bgRepeat="no-repeat"
        bgColor="white"
      >
        <Nav />
        <Consultation />
        <Footer />
      </VStack>
    );
  }
}
export default ConsultationPage;
