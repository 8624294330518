import {
  Box,
  Heading,
  VStack,
  HStack,
  useBreakpointValue,
  Text,
  Image,
  Divider,
  List,
  ListItem,
  ListIcon,
  Button,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import NavBar from "../Overall/Navbar";
import Footer from "../Overall/Footer";
import Consultation from "../Overall/Consultation";
import DSATPrivatePackages from "./ACTPrivatePackages";
import ACTPrivatePackages from "./ACTPrivatePackages";
function ACTPrivate() {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const isDesktop = useBreakpointValue({ base: false, md: true });

  if (isDesktop) {
    return (
      <VStack w="100%">
        <NavBar />
        <Heading color="#143345" mt="5%">
          ACT Private Lessons
        </Heading>
        <Text>$50/hr</Text>
        <Text textAlign="center" w="60%" fontFamily="book">
          Ideal for students looking for in-depth content assistance and
          strategy, while reinforcing concepts with practice.
        </Text>
        
        <HStack justifyContent="center" w="85%" p="5%" spacing="5%">
          <VStack w="100%" minH="40vh">
            <Image src={"../icons/arm.png"} w="20%" />
            <Heading size="md" w="100%" textAlign="center">
              Instruction
            </Heading>
            <List>
              <ListItem>
                <HStack>
                  <Image
                    src={"../icons/check.png"}
                    boxSize="1.5rem"
                    alt="Bullet point"
                    // mr={1}
                  />
                  <Box>
                    <Text fontWeight="medium" fontSize="sm">
                      1:1 one-hour tutoring session/week
                    </Text>
                  </Box>
                </HStack>
              </ListItem>
            </List>
            <Text fontFamily="book" textAlign="center">
              In private lessons, a tutor will work with you to identify your
              specific skill gaps and provide further assistance.
            </Text>
          </VStack>
          <VStack w="100%" minH="40vh">
            <Image src={"../icons/teacher.png"} w="20%" />
            <Heading size="md" w="100%" textAlign="center">
              Reinforcement
            </Heading>
            <List>
              <ListItem>
                <HStack>
                  <Image
                    src={"../icons/check.png"}
                    boxSize="1.5rem"
                    alt="Bullet point"
                    // mr={1}
                  />
                  <Box>
                    <Text fontWeight="medium" fontSize="sm">
                      Personalized homework assignments
                    </Text>
                  </Box>
                </HStack>
              </ListItem>
            </List>
            <Text fontFamily="book" textAlign="center">
              The personalized homework assignments will target specific weak
              points that the student has.
            </Text>
          </VStack>

          <VStack w="100%" minH="40vh">
            <Image src={"../icons/hands.png"} w="20%" />
            <Heading size="md" w="100%" textAlign="center">
              Support
            </Heading>
            <List>
              <ListItem>
                <HStack>
                  <Image
                    src={"../icons/check.png"}
                    boxSize="1.5rem"
                    alt="Bullet point"
                    // mr={1}
                  />
                  <Box>
                    <Text fontSize="sm">
                      Proctored bi-weekly practice tests
                    </Text>
                  </Box>
                </HStack>
              </ListItem>

              <ListItem>
                <HStack>
                  <Image
                    src={"../icons/check.png"}
                    boxSize="1.5rem"
                    alt="Bullet point"
                    // mr={1}
                  />
                  <Box>
                    <Text fontSize="sm">Access to weekly office hours </Text>
                  </Box>
                </HStack>
              </ListItem>
            </List>
            <Text fontFamily="book" textAlign="center">
              In our weekly office hours, students can come in and ask any
              questions they may have. We give the proctored bi-weekly practice
              tests so that students can track their progress.
            </Text>
          </VStack>
        </HStack>
        <ACTPrivatePackages />
        <Consultation />
        <Footer />
      </VStack>
    );
  } else if (isMobile) {
    return (
      <VStack w="100%">
        <NavBar />
        <Heading color="#143345" mt="5%">
          ACT Private Lessons
        </Heading>
        <Text>$50/hr</Text>
        <Text textAlign="center" w="80%" fontFamily="book">
          Ideal for students looking for in-depth content assistance and
          strategy, while reinforcing concepts with practice.
        </Text>
        
        <VStack justifyContent="center" w="85%" p="5%" spacing="20px">
          <VStack w="100%">
            <Image src={"../icons/arm.png"} w="20%" />
            <Heading size="md" w="100%" textAlign="center">
              Instruction
            </Heading>
            <List>
              <ListItem>
                <HStack>
                  <Image
                    src={"../icons/check.png"}
                    boxSize="1.5rem"
                    alt="Bullet point"
                    // mr={1}
                  />
                  <Box>
                    <Text fontWeight="medium" fontSize="sm">
                      1:1 one-hour tutoring session/week
                    </Text>
                  </Box>
                </HStack>
              </ListItem>
            </List>
            <Text fontFamily="book" textAlign="center">
              In private lessons, a tutor will work with you to identify your
              specific skill gaps and provide further assistance.
            </Text>
          </VStack>
          <VStack w="100%">
            <Image src={"../icons/teacher.png"} w="20%" />
            <Heading size="md" w="100%" textAlign="center">
              Reinforcement
            </Heading>
            <List>
              <ListItem>
                <HStack>
                  <Image
                    src={"../icons/check.png"}
                    boxSize="1.5rem"
                    alt="Bullet point"
                    // mr={1}
                  />
                  <Box>
                    <Text fontWeight="medium" fontSize="sm">
                      Personalized homework assignments
                    </Text>
                  </Box>
                </HStack>
              </ListItem>
            </List>
            <Text fontFamily="book" textAlign="center">
              The personalized homework assignments will target specific weak
              points that the student has.
            </Text>
          </VStack>

          <VStack w="100%">
            <Image src={"../icons/hands.png"} w="20%" />
            <Heading size="md" w="100%" textAlign="center">
              Support
            </Heading>
            <List>
              <ListItem>
                <HStack>
                  <Image
                    src={"../icons/check.png"}
                    boxSize="1.5rem"
                    alt="Bullet point"
                    // mr={1}
                  />
                  <Box>
                    <Text fontSize="sm">
                      Proctored bi-weekly practice tests
                    </Text>
                  </Box>
                </HStack>
              </ListItem>

              <ListItem>
                <HStack>
                  <Image
                    src={"../icons/check.png"}
                    boxSize="1.5rem"
                    alt="Bullet point"
                    // mr={1}
                  />
                  <Box>
                    <Text fontSize="sm">Access to weekly office hours </Text>
                  </Box>
                </HStack>
              </ListItem>
            </List>
            <Text fontFamily="book" textAlign="center">
              In our weekly office hours, students can come in and ask any
              questions they may have. We give the proctored bi-weekly practice
              tests so that students can track their progress.
            </Text>
          </VStack>
        </VStack>
        <ACTPrivatePackages />
        <Consultation />
        <Footer />
      </VStack>
    );
  }
  return null;
}
export default ACTPrivate;
